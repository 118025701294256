.home-page {
  .add-header {
    background-color: $top-nav-color;

    .add-header-component {
      position: relative;

      .ban-img {
        .vertical-img {
          position: relative;

          .vertical-image-css {
            // width: 400.91px;
            // height: 500px;
            width: 439.91px;
            height: 664px;

            @media screen and (max-width: 1050px) {
              width: 400px;
            }
          }

          .upper-image {
            .upper-image-css {
              /* CARD */
              position: absolute;
              width: 400px;
              height: 161px;
              // left: 752.04px;
              // top: 629px;
              right: 230.96px;
              top: 412px;
              background: #ffffff;
              box-shadow: 0px 4.13064px 51.633px rgba(0, 0, 0, 0.1);
              border-radius: 16.5226px;

              @media screen and (max-width: 1366px) {
                width: 400px;
                right: 200.96px;
                top: 412px;
              }

              @media screen and (max-width: 1190px) {
                width: 300px;
                right: 130.96px;
                top: 412px;
              }
            }
          }
        }
      }

      .head-data {
        @media screen and (max-width: 991px) {
          margin-bottom: 20px;
        }

        // margin-top: 12%;

        .speak-up {
          width: 566px;
          height: 297px;
          font-family: "Gilroy";
          font-style: normal;
          font-weight: 700;
          font-size: 55px;
          line-height: 64px;
          /* or 114% */
          text-transform: capitalize;
          color: #ffffff;

          @media screen and (max-width: 580px) {
            width: 100%;
          }

          @media screen and (max-width: 500px) {
            font-size: 37px;
            line-height: 47px;
          }

          @media screen and (max-width: 443px) {
            height: 40%;
          }

          @media screen and (max-width: 750px) {
            width: 100%;
            height: auto;
            height: auto;
          }
        }

        .bottom-speak {
          /* Cum et convallis risus placerat aliquam, nunc. Scelerisque aliquet faucibus tincidunt eu adipiscing sociis arcu lorem porttitor. */

          width: 479px;
          height: 88px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 29px;
          /* or 172% */
          text-transform: capitalize;
          color: #ffffff;

          @media screen and (max-width: 500px) {
            font-size: 22px;
            line-height: 29px;
            height: auto;
            width: 100%;
          }
        }

        .bottom-details {
          @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: center;
            justify-self: center;
          }

          .img-three-add {
            margin-right: 20px;

            @media screen and (max-width: 500px) {
              margin-right: 0px;
            }

            .first {
              position: absolute;
              // left: 0px;
              z-index: 100;
            }

            .second {
              position: relative;
              left: 25px;
              z-index: 1;
            }

            .third {
              position: relative;
            }
          }

          .happy-customer {
            margin-right: 60px;

            @media screen and (max-width: 500px) {
              margin-top: 10px;
              margin-right: 0px;
            }

            .count {
              width: 84.27px;
              height: 41px;
              font-family: "DM Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 29.2138px;
              line-height: 41px;
              /* identical to box height, or 140% */
              letter-spacing: -0.973792px;
              color: #ffffff;

              @media screen and (max-width: 500px) {
                text-align: center;
                width: 100%;
                height: auto;
              }
            }

            .para {
              position: absolute;
              width: 141.45px;
              height: 24px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 15.5807px;
              line-height: 23px;
              /* identical to box height, or 150% */
              color: #ffffff;
              mix-blend-mode: normal;

              @media screen and (max-width: 500px) {
                position: unset;
                width: 100%;
                height: auto;
              }
            }
          }

          .vertical-line {
            margin: 15px 0px;
            width: 2px;
            height: 37.98px;
          }

          .rating-details {
            margin-left: 22px;

            @media screen and (max-width: 500px) {
              margin-left: 0px;
            }

            @media screen and (max-width: 500px) {
              text-align: center;
            }

            .rating-avg {
              width: 70.22px;
              height: 41px;
              font-family: "DM Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 29.2138px;
              line-height: 41px;
              /* identical to box height, or 140% */
              letter-spacing: -0.973792px;
              color: #ffffff;

              @media screen and (max-width: 500px) {
                margin-top: 10px;
                text-align: center;
                width: 100%;
                height: auto;
              }
            }

            .rating-diag {
              align-items: center;
              width: 51.16px;
              height: 24px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 15.5807px;
              line-height: 23px;
              color: #ffffff;
              mix-blend-mode: normal;

              @media screen and (max-width: 500px) {
                text-align: center;
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }

  .social-media-home-page {
    .social-media-component {
      .join-us-detail {
        .text {
          h1 {
            width: 611px;
            height: 240px;
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 60px;
            text-transform: capitalize;
            color: #323232;

            @media screen and (max-width: 1200px) {
              font-size: 35px;
              list-style: 45px;
              width: 511px;
            }

            @media screen and (max-width: 1200px) {
              font-size: 35px;
              list-style: 45px;
              width: 411px;
            }

            @media screen and (max-width: 750px) {
              width: 100%;
              font-size: 50px;
              line-height: 60px;
              height: auto;
              margin-bottom: 30px;
            }

            span {
              color: #867cf2 !important;
            }
          }

          p {
            width: 565px;
            height: 59px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 29px;
            /* or 172% */
            text-transform: capitalize;
            color: #413d45;

            @media screen and (max-width: 750px) {
              width: 100%;
              height: auto;
            }
          }
        }

        .os-type {
          display: flex;

          @media screen and (max-width: 600px) {
            flex-direction: column;
          }

          img {
            @media screen and (max-width: 600px) {
              width: 100%;
              margin-bottom: 30px;
            }
          }

          .apple {
            margin-right: 20px;
          }
        }
      }

      .types {
        @media screen and (max-width: 750px) {
          margin-top: 30px;
        }

        .os-type-image {
          flex-direction: column;
        }
      }
    }
  }

  .features-home-page {
    background-color: #f2f2f2;

    .features-component {
      // padding: 50px 0px;

      .feature-image {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 58px 0px;
        background-image: url("../images/Home/Choosesec/bg.png");
        background-repeat: no-repeat;
        text-align: center;

        @media screen and (max-width: 991px) {
          text-align: center;
        }

        img {
          width: 399.85px;
          height: 615px;

          // @media screen and (max-width: 991px) {
          //   width: 499.85px;
          //   height: 715px;
          // }

          // @media screen and (max-width: 991px) {
          //   width: 399.85px;
          //   height: 715px;
          // }

          @media screen and (max-width: 400px) {
            width: 100%;
            height: auto;
          }

          @media screen and (max-width: 360px) {
            width: 100%;
            height: auto;
          }
        }
      }

      .feature-details {
        .content {
          h5 {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 28px;
            /* identical to box height, or 127% */
            text-transform: capitalize;
            color: #867cf2;
          }

          h1 {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 60px;
            text-transform: capitalize;
            color: #323232;

            span {
              color: #867cf2;
            }
          }

          /* src/FeatureList.css */

          .feature-list {
            position: relative;
            padding-left: 30px;

            .feature-item {
              display: flex;
              align-items: flex-start;
              position: relative;

              input[type="radio"] {
                position: absolute;
                border: #6e2bff;
                top: 8px;
                width: 12px;
                height: 12px;
                accent-color: #6e2bff;
                opacity: 0; // Hide the default radio button
                cursor: pointer;
              }

              input[type="radio"]+label {
                &::before {
                  content: "";
                  position: absolute;
                  left: -23.5px;
                  top: 7px;
                  width: 12px;
                  height: 12px;
                  border: 2px solid #6e2bff; // Border color
                  border-radius: 50%;
                  background: #fff;
                }

                &::after {
                  content: "";
                  position: absolute;
                  left: -23.5px;
                  top: 5px;
                  width: 12px;
                  height: 12px;
                  background: #fff; // Inner circle color
                  border-radius: 50%;
                  display: none;
                }
              }

              input[type="radio"]:checked+label {
                &::before {
                  background-color: #6e2bff; // Background color when checked
                }

                &::after {
                  border: 2px solid blue;
                }
              }

              label {
                // margin-left: 30px;
                // cursor: pointer;
              }

              h3 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 28px;
                /* identical to box height, or 127% */
                text-transform: capitalize;
                color: #867cf2;
              }

              p {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                /* or 156% */
                color: #808080;
              }

              // input[type="radio"]:checked+label h3 {
              //     // color: #6e2bff;
              //     /* Active state color */
              // }
            }

            &::before {
              content: "";
              position: absolute;
              left: 10px;
              top: 7.5px;
              bottom: 0;
              width: 2px;
              /* Adjust the width based on your image */
              background-image: url("../images/Home/Features/Verticalline.png");
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }

  .choose-kansensus-home-page {
    .choose-kansensus-component {
      background-image: url("../images/Home/Choosesec/bg.png");
      background-position: right;
      background-repeat: no-repeat;

      .choose-kansensus-details {
        .content {
          width: 550px !important;

          @media screen and (max-width: 991px) {
            width: 100% !important;
            height: auto;
          }

          h5 {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 28px;
            /* identical to box height, or 127% */
            text-transform: capitalize;
            color: #97a1ff;
          }

          h1 {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 50px;
            text-transform: capitalize;
            color: #323232;

            span {
              color: #867cf3;
            }
          }

          .para {
            width: 500px;

            @media screen and (max-width: 991px) {
              width: 100% !important;
              height: auto;
            }

            p {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 28px;
              /* or 156% */
              color: #808080;
            }
          }
        }
      }

      .choose-kansensus-image {
        @media screen and (max-width: 991px) {
          text-align: center;
        }

        img {
          width: 100%;
          height: auto;

          // @media screen and (max-width: 1290px) {
          //   width: 400px;
          // }

          // @media screen and (max-width: 1100px) {
          //   width: 350px;
          //   height: 600px;
          // }

          // @media screen and (max-width: 991px) {
          //   width: 459.85px;
          //   height: 715px;
          // }

          // @media screen and (max-width: 991px) {
          //   width: 100%;
          //   height: auto;
          // }
        }
      }
    }
  }

  .our-users-home-page {
    .our-users-component {
      position: relative;
      overflow: hidden;
      width: 100%;
      max-width: 1400px;
      /* Adjust based on your design */
      margin: 0 auto;

      .content {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          width: 808px;
          height: 51px;
          font-family: "Gilroy";
          font-style: normal;
          font-weight: 700;
          font-size: 45px;
          line-height: 114.2%;
          text-align: center;
          color: #2a2222;

          @media screen and (max-width: 750px) {
            width: 100%;
            height: auto;
          }
        }

        h3 {
          width: 649px;
          height: 42px;
          font-family: "Gilroy";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 134.2%;
          text-align: center;
          text-transform: capitalize;
          color: #484848;

          @media screen and (max-width: 750px) {
            width: 100%;
            height: auto;
            height: auto;
          }
        }
      }

      // .card-details {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   transition: transform 0.5s ease;

      //   .card-our-user {
      //     flex: 0 0 336.6px;
      //     box-sizing: border-box;
      //     margin: 0 1px;
      //     padding: 10px 18px;
      //     border: none;
      //     width: 336.6px;
      //     height: 383.4px;
      //     background: #f5f2ed;
      //     opacity: 0.4;
      //     border-radius: 10.8px;
      //     transform: scale(0.8);
      //     transition: transform 0.5s ease, opacity 0.5s ease;

      //     &.active-card {
      //       margin-top: 45px;
      //       opacity: 1;
      //       // transform: scale(1);
      //     }

      //     svg {
      //       width: 67.5px;
      //       height: 72px;
      //     }

      //     .comment {
      //       width: 260.46px;
      //       height: 220.6px;
      //       font-family: "Gilroy";
      //       font-style: normal;
      //       font-weight: 600;
      //       font-size: 23.4px;
      //       line-height: 32px;
      //       letter-spacing: -0.63px;
      //       color: #413d45;
      //     }

      //     .name {
      //       max-width: 260px;
      //       height: 17px;
      //       font-family: "Gilroy";
      //       font-style: normal;
      //       font-weight: 500;
      //       font-size: 14px;
      //       line-height: 16px;
      //       letter-spacing: -0.126px;
      //       color: #67646a;
      //     }

      //     .detail {
      //       max-width: 200px;
      //       height: 15px;
      //       font-family: "Gilroy";
      //       font-style: normal;
      //       font-weight: 500;
      //       font-size: 14px;
      //       line-height: 14px;
      //       letter-spacing: -0.108px;
      //       color: #413d45;
      //     }
      //   }
      // }

      // .center .slick-slide {
      //   opacity: 0.5;
      //   transition: opacity 0.3s ease;
      //   div {
      //     opacity: 10;
      //     margin-top: 20px;
      //   }
      // }

      // .center .slick-center {
      //   opacity: 10;
      //   margin-top: 25px;
      // }

      // .slick-slide {
      //   width: 336px !important;
      // }
      // .slick-slide div {
      //   padding: 20px;
      //   font-size: 36px;
      //   height: 383.4px;
      //   margin: 0 10px;

      //   width: 336.6px !important;
      //   background: #f5f2ed;
      //   opacity: 0.4;
      //   border-radius: 10.8px;

      //   svg {
      //     width: 67.5px;
      //     height: 72px;
      //   }
      //   .comment {
      //     width: 260.46px;
      //     height: 210.6px;
      //     font-family: "Gilroy";
      //     font-style: normal;
      //     font-weight: 600;
      //     font-size: 23.4px;
      //     line-height: 32px;
      //     letter-spacing: -0.63px;
      //     color: #413d45;
      //   }
      //   .name {
      //     max-width: 260px;
      //     height: 17px;
      //     font-family: "Gilroy";
      //     font-style: normal;
      //     font-weight: 500;
      //     font-size: 14px;
      //     line-height: 16px;
      //     letter-spacing: -0.126px;
      //     color: #67646a;
      //   }

      //   .detail {
      //     max-width: 250px;
      //     height: 15px;
      //     font-family: "Gilroy";
      //     font-style: normal;
      //     font-weight: 500;
      //     font-size: 14px;
      //     line-height: 14px;
      //     letter-spacing: -0.108px;
      //     color: #413d45;
      //   }
      // }

      // .slick-prev:before,
      // .slick-next:before {
      //   color: red;
      // }

      // .slider-container {
      //   width: 100%;
      //   margin: auto;
      //   padding: 40px 118px;

      //   // svg {
      //   //   width: 67.5px;
      //   //   height: 72px;
      //   // }

      //   // .comment {
      //   //   width: 260.46px;
      //   //   height: 220.6px;
      //   //   font-family: "Gilroy";
      //   //   font-style: normal;
      //   //   font-weight: 600;
      //   //   font-size: 23.4px;
      //   //   line-height: 32px;
      //   //   letter-spacing: -0.63px;
      //   //   color: #413d45;
      //   // }

      //   // .name {
      //   //   max-width: 260px;
      //   //   height: 17px;
      //   //   font-family: "Gilroy";
      //   //   font-style: normal;
      //   //   font-weight: 500;
      //   //   font-size: 14px;
      //   //   line-height: 16px;
      //   //   letter-spacing: -0.126px;
      //   //   color: #67646a;
      //   // }

      //   // .detail {
      //   //   max-width: 200px;
      //   //   height: 15px;
      //   //   font-family: "Gilroy";
      //   //   font-style: normal;
      //   //   font-weight: 500;
      //   //   font-size: 14px;
      //   //   line-height: 14px;
      //   //   letter-spacing: -0.108px;
      //   //   color: #413d45;
      //   // }
      // }

      .button-btn {
        border: none !important;
        background: transparent !important;
        margin: 10px 20px;
      }

      .slider-container {
        width: 100%;
        margin: 0px;
        padding: 40px 0;
      }

      .slick-prev {
        border: none;
        background: transparent;
        // left: 500px;
        // top: 475px;
        // background-image: url("../images/Home/ouruser/Back.png");
      }


      .slick-next {
        border: none;
        background: transparent;

        // right: 500px;
        // top: 475px;
        // background-image: url("../images/Home/ouruser/Forward.png");
      }



      .center .slick-slide {
        opacity: 0.5;
        transition: opacity 0.3s ease;
      }

      .center .slick-center {
        opacity: 1;
        margin-top: 28px;
      }

      .slide {
        display: flex !important;
        justify-content: center;
        align-items: center;
      }

      .testimonial {
        background: #f9f9f9;
        padding: 20px;
        border-radius: 10px;
        max-width: 300px;
        margin: auto;
        height: 383.4px;
        background: #f5f2ed;
        border-radius: 10.8px;

        svg {
          width: 67.5px;
          height: 72px;
        }

        .comment {
          width: 260.46px;
          height: 220.6px;
          font-family: "Gilroy";
          font-style: normal;
          font-weight: 600;
          font-size: 23.4px;
          line-height: 32px;
          letter-spacing: -0.63px;
          color: #413d45;
        }

        .name {
          max-width: 260px;
          height: 17px;
          font-family: "Gilroy";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.126px;
          color: #67646a;
        }

        .detail {
          max-width: 200px;
          height: 15px;
          font-family: "Gilroy";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: -0.108px;
          color: #413d45;
        }
      }

      // .testimonial p {
      //   font-size: 1rem;
      //   line-height: 1.5;
      //   margin: 10px 0;
      // }

      // .testimonial h4 {
      //   margin-top: 15px;
      //   font-size: 1.2rem;
      //   font-weight: bold;
      // }

      .slick-prev:before,
      .slick-next:before {
        color: #000;
      }


    }
  }

  .close-home-page {
    .closer-component {
      .content {
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          font-family: "Gilroy";
          font-style: normal;
          font-weight: 700;
          font-size: 50px;
          line-height: 60px;
          text-transform: capitalize;
          color: #323232;
        }

        h3 {
          width: 817px;
          height: 56px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          /* or 156% */
          text-align: center;
          color: #808080;

          @media screen and (max-width: 800px) {
            width: 100%;
            font-size: 16px;
            height: auto;
          }
        }
      }

      .add-banner {
        text-align: center;

        img {
          width: 100%;
          height: auto;

          // @media screen and (max-width: 1440px) {
          //   width: 1200px;
          //   height: 800px;
          // }

          // @media screen and (max-width: 1199px) {
          //   width: 900px;
          //   height: 700px;
          // }

          // @media screen and (max-width: 900px) {
          //   width: 700px;
          //   height: 500px;
          // }

          // @media screen and (max-width: 900px) {
          //   width: 400px;
          //   height: 200px;
          // }

          // @media screen and (max-width: 450px) {
          //   width: 300px;
          //   height: 140px;
          // }
        }
      }
    }
  }

  .joinwaitlist-home-page {
    .joinwaitlist-component {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 1279px;
      height: 302px;
      background-color: #7880cb;
      border-radius: 30px;
      background-image: url("../images/Home/joincomponent/bg.png");
      /* Your background image */
      background-position: right center;
      /* Position the image to the right center */
      background-repeat: no-repeat;
      /* Ensure the image doesn't repeat */
      background-size: contain;

      @media screen and (max-width: 750px) {
        width: 100%;
        font-size: 44px;
        line-height: 54px;
      }

      /* Adjust the size of the image to fit the element */
      h1 {
        width: 689px;
        height: 85.16px;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 77px;
        text-transform: capitalize;
        color: #fafafa;

        @media screen and (max-width: 750px) {
          width: 100%;
          font-size: 44px;
          line-height: 54px;
        }

        @media screen and (max-width: 400px) {
          width: 100%;
          height: auto;
          font-size: 24px;
          line-height: 34px;
        }
      }

      h5 {
        width: 574px;
        height: 34.28px;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 31px;
        text-transform: capitalize;
        color: #fafafa;

        @media screen and (max-width: 750px) {
          font-size: 16px;
          line-height: 26px;
        }

        @media screen and (max-width: 750px) {
          width: 100%;
          line-height: 54px;
        }

        @media screen and (max-width: 400px) {
          margin-top: 10px !important;
          width: 100%;
          font-size: 14px;
          line-height: 34px;
        }
      }

      .email {
        width: 580px;
        height: 66.36px;
        border-radius: 50px;
        position: relative;
        display: flex;
        align-items: center;

        @media screen and (max-width: 750px) {
          width: 450px;
        }

        @media screen and (max-width: 750px) {
          width: 100%;
        }

        @media screen and (max-width: 400px) {
          width: 300px;
        }

        .input-wrapper {
          position: relative;
          width: 100%;

          .name {
            /* Rectangle 3 */
            box-sizing: border-box;
            width: 100%;
            height: 66.36px;
            background: white;
            border: none;
            font-family: "Lato";
            border-radius: 50px;
            color: black;
            font-size: 14px;
            font-weight: 600;
          }

          button {
            width: 174px;
            height: 49px;

            @media screen and (max-width: 750px) {
              width: 100px;
            }
          }

          .email-button {
            gap: 10px;
            position: absolute;
            right: 12px;
            top: 32px;
            transform: translateY(-50%);
            background: #97a1ff;
            border-radius: 50px;
            border: none;
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #ffffff;
            flex: none;
            order: 0;
            flex-grow: 0;

            @media screen and (max-width: 750px) {
              font-size: 13px;
            }

            @media screen and (max-width: 750px) {
              display: none;
            }
          }

          .email-button:hover {
            background-color: #0056b3;
            /* Darker shade for hover effect */
          }
        }

        // .input-wrapper input {
        //     width: calc(100% - 50px);
        //     /* Adjust width considering the button width */
        //     padding-right: 50px;
        //     /* Adjust padding considering the button width */
        // }
      }

      .email-button-mobile {
        margin-top: 10px;
        gap: 10px;
        transform: translateY(-50%);
        background: #97a1ff;
        border-radius: 50px;
        border: none;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }
  }
}