.page-fotter {
  max-width: 1402px !important;
  height: 383px;
  background: #000000;
  border-radius: 52px 52px 0px 0px;
  padding: 80px 70px;
  @media screen and (max-width: 750px) {
    height: 883px;
  }
  .horizontol-line {
    @media screen and (max-width: 1250px) {
      width: 1100px;
    }
    @media screen and (max-width: 1170px) {
      width: 1030px;
    }
    @media screen and (max-width: 1100px) {
      width: 950px;
    }
    @media screen and (max-width: 820px) {
      display: none;
    }
  }
  h1 {
    margin-bottom: 15px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 42px;
    /* identical to box height, or 191% */
    text-transform: capitalize;
    color: #ffffff;
  }

  ul {
    li {
      margin-bottom: 15px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      /* identical to box height, or 186% */
      text-transform: capitalize;
      color: #ffffff;
    }
  }

  .brand-col {
    .brand-name {
      img {
        margin-right: 10px;
      }

      p {
        font-family: "Bahnschrift";
        font-style: normal;
        font-weight: 400;
        font-size: 38.6202px;
        line-height: 46px;
        letter-spacing: -0.01em;
        color: #ffffff;
      }
    }

    .intro {
      width: 221px;
      height: 54px;
      font-family: "Gilroy";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      /* or 150% */
      text-transform: capitalize;
      color: #ffffff;
    }
  }

  .contact-us {
    @media screen and (max-width: 750px) {
      text-align: center;
      margin-top: 20px;
    }
    .contact-us-content {
      svg {
        margin-right: 10px;
      }

      span {
        margin-bottom: 15px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        /* identical to box height, or 186% */
        text-transform: capitalize;
        color: #ffffff;
      }
    }
  }

  .copy-right {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */
    text-transform: capitalize;
    color: #ffffff;
    margin-left: 39px;
    @media screen and (max-width: 820px) {
      display: none !important;
    }
  }

  .social-media {
    margin-right: 59px;
    @media screen and (max-width: 820px) {
      display: none !important;
    }
    svg {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .tablet-social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .copy-right-tablet {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height, or 150% */
      text-transform: capitalize;
      color: #ffffff;
      margin-left: 39px;
    }

    .social-media-tablet {
      margin-right: 59px;
      margin-left: 20%;
      margin-top: 20px;
      svg {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .links {
    @media screen and (max-width: 750px) {
      text-align: center;
      margin-top: 20px;
    }
  }
}
