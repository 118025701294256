.terms-and-condition {
  h1 {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    text-transform: capitalize;
    color: #323232;
  }
  h2 {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    text-transform: capitalize;
    color: #867cf2 !important;
  }
  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 29px;
    /* or 172% */
    text-transform: capitalize;
    color: #413d45;
  }
}
