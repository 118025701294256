.sub-heading-style {
  font-size: 12px !important;
  font-weight: 500;
}
.list-style-dot {
  list-style: circle !important;
}
.sub-def-heading {
  font-size: 13px;
}

.option-alphabat {
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
}
