.theme-popup {
    /* Settings */
    border-radius: 30px;
    padding: 30px;

    .modal-body {
        padding: 40px;
    }

    .modal-content {
        background-color: #232D3F;
        border-radius: 30px;

        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            color: #FFFFFF;
            opacity: 0.96;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
        }

        button {
            width: 212px;
            height: 48px;
        }

        .btn-cancel {
            background-color: $color-white;
            border: none;
            color: $theme-background-color;
            font-weight: 700;
        }

        .btn-okay {
            background-color: $theme-btn-color;
            border: none;
        }
    }
}



/* VideoModal.css */

.video-theme-popup {
    .modal-content {
        background-color: transparent;
        /* Set background color of modal content */
        border: none;
        box-shadow: none;
        padding: 0px !important;
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        /* Semi-transparent overlay */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
    }

    .modal-content {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        position: relative;
        width: 80%;
        max-width: 800px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 20px;
        color: #fff;
        /* Group 34770 */
        width: 25.51px;
        height: 25.51px;
    }

    iframe {
        width: 100%;
        height: 371px;
        border: none;
        border-radius: 8px;
    }
}