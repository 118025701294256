
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: bolder;
  src: url("../fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: bolder;
  src: url("../fonts/Gilroy/Gilroy-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/Gilroy/Gilroy-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/Gilroy/Gilroy-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/Gilroy/Gilroy-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Gilroy/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/Gilroy/Gilroy-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/Gilroy/Gilroy-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Gilroy/Gilroy-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Gilroy';
  font-style: italic;
  font-weight: bold;
  src: url("../fonts/Gilroy/Gilroy-SemiBoldItalic.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  /* font-family: "Gilroy"; */
  background-color: #FAFAFA;
  color: black;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin-left: 0px;
  margin-right: 0px;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy", sans-serif !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #005b41;
}

::-webkit-scrollbar-thumb {
  background: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: unset;
}

ul {
  padding: 0;
}
ul li {
  list-style: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #19212F inset !important;
  -webkit-text-fill-color: #FFFFFF;
}

.cursor {
  cursor: pointer;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.lh-0-ls-5 {
  line-height: unset !important;
  letter-spacing: 5px;
}

.color-dark {
  color: #9C8B58;
}

.color-black {
  color: #2D2D2D;
}

.color-blue {
  color: #2C3E57;
}

.color-grey {
  color: #949494;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.go-back {
  width: 100%;
  text-align: start;
}

.line-podcast {
  /* Vector 199 */
  opacity: 0.1;
  border: 1px solid #ffffff;
}

.vertical-line {
  /* Line 1 */
  background: #ffffff;
  opacity: 0.4;
  border: 1.94758px solid #dddddd;
}
@media screen and (max-width: 820px) {
  .vertical-line {
    display: none;
  }
}

.custom-back {
  background-color: #1A212F;
  min-height: 963px;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:not(:placeholder-shown) ~ label::after {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:focus ~ label::after {
  border: none;
  background-color: transparent !important;
}

.form-floating > .form-control:focus ~ label {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:focus {
  border: none !important;
  background-color: #19212F;
}

.vertical-line-video {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
}
@media screen and (max-width: 820px) {
  .vertical-line-video {
    display: none;
  }
}

.default-align {
  align-items: start !important;
}

.btn-theme-default {
  background: #ED1B23;
  color:white;
  border-radius: 57px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
  text-transform: capitalize;
  /* color: #24252d; */
}

.btn-theme-default:hover {
  background: #ED1B23;
}

.span-kansensus {
  color: #ED1B23;
}

.form-floating label {
  font-size: 14px;
  color: #949494;
}
.form-floating.small-input .form-control {
  height: 43px;
}
.form-floating.small-input label {
  top: -7px;
}
.form-floating .form-control {
  border-radius: 8px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.form-floating .form-control:not(:-moz-placeholder-shown) {
  padding-top: 0;
  padding-bottom: 0;
}
.form-floating .form-control:not(:placeholder-shown) {
  padding-top: 0;
  padding-bottom: 0;
}
.form-floating .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 1;
  top: -3px;
  left: 10px;
  background: transparent;
  width: auto;
  height: auto;
  padding: 0 5px;
  color: black;
}
.form-floating .form-control:not(:placeholder-shown) ~ label {
  opacity: 1;
  top: -3px;
  left: 10px;
  background: transparent;
  width: auto;
  height: auto;
  padding: 0 5px;
  color: black;
}
.form-floating .form-control:focus ~ label {
  opacity: 1;
  top: -3px;
  left: 10px;
  width: auto;
  height: auto;
  padding: 0 5px;
  color: black;
  background: transparent;
}

.form-floating > .form-control {
  padding: 17px;
  min-height: 0px;
}

.form-floating > .form-control:focus ~ label::after {
  border: none;
  background-color: transparent;
}

.form-control {
  border: 1px solid #DDDDDD;
  outline: none;
}
.form-control:hover, .form-control:focus {
  box-shadow: none;
  border: 1px solid #FFFFFF;
  outline: none;
}

.input:-internal-autofill-selected {
  background-color: #19212f !important;
}

.password-input {
  position: relative;
}
.password-input .eye-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 16px;
  cursor: pointer;
  color: #949494;
}

.horizontol-line {
  width: 1177px;
  height: 0px;
  border: 1px solid #333333;
}

.email-button-mobile {
  display: none; /* Default to hidden */
}

@media only screen and (max-width: 750px) {
  .email-button-mobile {
    display: inline-block; /* or any other display property you prefer */
  }
}
.children {
  min-height: 340px;
}

.theme-popup {
  /* Settings */
  border-radius: 30px;
  padding: 30px;
}
.theme-popup .modal-body {
  padding: 40px;
}
.theme-popup .modal-content {
  background-color: #232D3F;
  border-radius: 30px;
}
.theme-popup .modal-content h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;
  opacity: 0.96;
}
.theme-popup .modal-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}
.theme-popup .modal-content button {
  width: 212px;
  height: 48px;
}
.theme-popup .modal-content .btn-cancel {
  background-color: #FFFFFF;
  border: none;
  color: #FAFAFA;
  font-weight: 700;
}
.theme-popup .modal-content .btn-okay {
  background-color: #005B41;
  border: none;
}

/* VideoModal.css */
.video-theme-popup .modal-content {
  background-color: transparent;
  /* Set background color of modal content */
  border: none;
  box-shadow: none;
  padding: 0px !important;
}
.video-theme-popup .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.video-theme-popup .modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.video-theme-popup .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  /* Group 34770 */
  width: 25.51px;
  height: 25.51px;
}
.video-theme-popup iframe {
  width: 100%;
  height: 371px;
  border: none;
  border-radius: 8px;
}

.home-page .add-header {
  /* background-color: ; */
  /* background: rgb(147,74,252);
  background: linear-gradient(59deg, rgba(147,74,252,1) 25%, rgba(73,164,253,1) 92%);  */
}
.home-page .add-header .add-header-component {
  position: relative;
}
.home-page .add-header .add-header-component .ban-img .vertical-img {
  position: relative;
}
.home-page .add-header .add-header-component .ban-img .vertical-img .vertical-image-css {
  width: 439.91px;
  height: 664px;
}
@media screen and (max-width: 1050px) {
  .home-page .add-header .add-header-component .ban-img .vertical-img .vertical-image-css {
    width: 400px;
  }
}
.home-page .add-header .add-header-component .ban-img .vertical-img .upper-image .upper-image-css {
  /* CARD */
  position: absolute;
  width: 400px;
  height: 161px;
  right: 230.96px;
  top: 412px;
  background: #ffffff;
  box-shadow: 0px 4.13064px 51.633px rgba(0, 0, 0, 0.1);
  border-radius: 16.5226px;
}
@media screen and (max-width: 1366px) {
  .home-page .add-header .add-header-component .ban-img .vertical-img .upper-image .upper-image-css {
    width: 400px;
    right: 200.96px;
    top: 412px;
  }
}
@media screen and (max-width: 1190px) {
  .home-page .add-header .add-header-component .ban-img .vertical-img .upper-image .upper-image-css {
    width: 300px;
    right: 130.96px;
    top: 412px;
  }
}
@media screen and (max-width: 991px) {
  .home-page .add-header .add-header-component .head-data {
    margin-bottom: 20px;
  }
}
.home-page .add-header .add-header-component .head-data .speak-up {
  width: 566px;
  height: 297px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 64px;
  text-transform: capitalize;
  color: #ED1B23;
  user-select: none;
}

@media screen and (max-width: 580px) {
  .home-page .add-header .add-header-component .head-data .speak-up {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .home-page .add-header .add-header-component .head-data .speak-up {
    font-size: 37px;
    line-height: 47px;
  }
}
@media screen and (max-width: 443px) {
  .home-page .add-header .add-header-component .head-data .speak-up {
    height: 40%;
  }
}
@media screen and (max-width: 750px) {
  .home-page .add-header .add-header-component .head-data .speak-up {
    width: 100%;
    height: auto;
    height: auto;
  }
}
.home-page .add-header .add-header-component .head-data .bottom-speak {
  /* Cum et convallis risus placerat aliquam, nunc. Scelerisque aliquet faucibus tincidunt eu adipiscing sociis arcu lorem porttitor. */
  width: 479px;
  height: 88px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 29px;
  /* or 172% */
  text-transform: capitalize;
  color: #ED1B23;
}
@media screen and (max-width: 500px) {
  .home-page .add-header .add-header-component .head-data .bottom-speak {
    font-size: 22px;
    line-height: 29px;
    height: auto;
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .home-page .add-header .add-header-component .head-data .bottom-details {
    flex-direction: column;
    align-items: center;
    justify-self: center;
  }
}
.home-page .add-header .add-header-component .head-data .bottom-details .img-three-add {
  margin-right: 20px;
}
@media screen and (max-width: 500px) {
  .home-page .add-header .add-header-component .head-data .bottom-details .img-three-add {
    margin-right: 0px;
  }
}
.home-page .add-header .add-header-component .head-data .bottom-details .img-three-add .first {
  position: absolute;
  z-index: 100;
}
.home-page .add-header .add-header-component .head-data .bottom-details .img-three-add .second {
  position: relative;
  left: 25px;
  z-index: 1;
}
.home-page .add-header .add-header-component .head-data .bottom-details .img-three-add .third {
  position: relative;
}
.home-page .add-header .add-header-component .head-data .bottom-details .happy-customer {
  margin-right: 60px;
}
@media screen and (max-width: 500px) {
  .home-page .add-header .add-header-component .head-data .bottom-details .happy-customer {
    margin-top: 10px;
    margin-right: 0px;
  }
}
.home-page .add-header .add-header-component .head-data .bottom-details .happy-customer .count {
  width: 84.27px;
  height: 41px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 29.2138px;
  line-height: 41px;
  /* identical to box height, or 140% */
  letter-spacing: -0.973792px;
  color: #ED1B23;
}
@media screen and (max-width: 500px) {
  .home-page .add-header .add-header-component .head-data .bottom-details .happy-customer .count {
    text-align: center;
    width: 100%;
    height: auto;
  }
}
.home-page .add-header .add-header-component .head-data .bottom-details .happy-customer .para {
  position: absolute;
  width: 141.45px;
  height: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15.5807px;
  line-height: 23px;
  /* identical to box height, or 150% */
  color: #ED1B23;
  mix-blend-mode: normal;
}
@media screen and (max-width: 500px) {
  .home-page .add-header .add-header-component .head-data .bottom-details .happy-customer .para {
    position: unset;
    width: 100%;
    height: auto;
  }
}
.home-page .add-header .add-header-component .head-data .bottom-details .vertical-line {
  margin: 15px 0px;
  width: 2px;
  height: 37.98px;
}
.home-page .add-header .add-header-component .head-data .bottom-details .rating-details {
  margin-left: 22px;
}
@media screen and (max-width: 500px) {
  .home-page .add-header .add-header-component .head-data .bottom-details .rating-details {
    margin-left: 0px;
  }
}
@media screen and (max-width: 500px) {
  .home-page .add-header .add-header-component .head-data .bottom-details .rating-details {
    text-align: center;
  }
}
.home-page .add-header .add-header-component .head-data .bottom-details .rating-details .rating-avg {
  width: 70.22px;
  height: 41px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 29.2138px;
  line-height: 41px;
  /* identical to box height, or 140% */
  letter-spacing: -0.973792px;
  color: #ED1B23;
}
@media screen and (max-width: 500px) {
  .home-page .add-header .add-header-component .head-data .bottom-details .rating-details .rating-avg {
    margin-top: 10px;
    text-align: center;
    width: 100%;
    height: auto;
  }
}
.home-page .add-header .add-header-component .head-data .bottom-details .rating-details .rating-diag {
  align-items: center;
  width: 51.16px;
  height: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15.5807px;
  line-height: 23px;
  color: #ED1B23;
  mix-blend-mode: normal;
}
@media screen and (max-width: 500px) {
  .home-page .add-header .add-header-component .head-data .bottom-details .rating-details .rating-diag {
    text-align: center;
    width: 100%;
    height: auto;
  }
}
.home-page .social-media-home-page .social-media-component .join-us-detail .text h1 {
  width: 611px;
  height: 240px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-transform: capitalize;
  color: #323232;
}
@media screen and (max-width: 1200px) {
  .home-page .social-media-home-page .social-media-component .join-us-detail .text h1 {
    font-size: 35px;
    list-style: 45px;
    width: 511px;
  }
}
@media screen and (max-width: 1200px) {
  .home-page .social-media-home-page .social-media-component .join-us-detail .text h1 {
    font-size: 35px;
    list-style: 45px;
    width: 411px;
  }
}
@media screen and (max-width: 750px) {
  .home-page .social-media-home-page .social-media-component .join-us-detail .text h1 {
    width: 100%;
    font-size: 50px;
    line-height: 60px;
    height: auto;
    margin-bottom: 30px;
  }
}
.home-page .social-media-home-page .social-media-component .join-us-detail .text h1 span {
  color: #ED1B23 !important;
}
.home-page .social-media-home-page .social-media-component .join-us-detail .text p {
  width: 565px;
  height: 59px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 29px;
  /* or 172% */
  text-transform: capitalize;
  color: #413d45;
}
@media screen and (max-width: 750px) {
  .home-page .social-media-home-page .social-media-component .join-us-detail .text p {
    width: 100%;
    height: auto;
  }
}
.home-page .social-media-home-page .social-media-component .join-us-detail .os-type {
  display: flex;
}
@media screen and (max-width: 600px) {
  .home-page .social-media-home-page .social-media-component .join-us-detail .os-type {
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .home-page .social-media-home-page .social-media-component .join-us-detail .os-type img {
    width: 100%;
    margin-bottom: 30px;
  }
}
.home-page .social-media-home-page .social-media-component .join-us-detail .os-type .apple {
  margin-right: 20px;
}
@media screen and (max-width: 750px) {
  .home-page .social-media-home-page .social-media-component .types {
    margin-top: 30px;
  }
}
.home-page .social-media-home-page .social-media-component .types .os-type-image {
  flex-direction: column;
}
.home-page .features-home-page {
  background-color: #f2f2f2;
}
.home-page .features-home-page .features-component .feature-image {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 58px 0px;
  background-image: url("../images/Home/Choosesec/bg.png");
  background-repeat: no-repeat;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .home-page .features-home-page .features-component .feature-image {
    text-align: center;
  }
}
.home-page .features-home-page .features-component .feature-image img {
  width: 399.85px;
  height: 615px;
}
@media screen and (max-width: 400px) {
  .home-page .features-home-page .features-component .feature-image img {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 360px) {
  .home-page .features-home-page .features-component .feature-image img {
    width: 100%;
    height: auto;
  }
}
.home-page .features-home-page .features-component .feature-details .content {
  /* src/FeatureList.css */
}
.home-page .features-home-page .features-component .feature-details .content h5 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */
  text-transform: capitalize;
  color: #ED1B23;
}
.home-page .features-home-page .features-component .feature-details .content h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-transform: capitalize;
  color: #323232;
}
.home-page .features-home-page .features-component .feature-details .content h1 span {
  color: #ED1B23;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list {
  position: relative;
  padding-left: 30px;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item input[type=radio] {
  position: absolute;
  border:#ED1B23;
  top: 8px;
  width: 12px;
  height: 12px;
  accent-color:#ED1B23;
  opacity: 0;
  cursor: pointer;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item input[type=radio] + label::before {
  content: "";
  position: absolute;
  left: -23.5px;
  top: 7px;
  width: 12px;
  height: 12px;
  border: 2px solid #ED1B23;
  border-radius: 50%;
  background: #fff;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item input[type=radio] + label::after {
  content: "";
  position: absolute;
  left: -23.5px;
  top: 5px;
  width: 12px;
  height: 12px;
  background: #ED1B23;
  border-radius: 50%;
  display: none;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item input[type=radio]:checked + label::before {
  background-color: #ED1B23;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item input[type=radio]:checked + label::after {
  border: 2px solid #ED1B23;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */
  text-transform: capitalize;
  color: #ED1B23;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */
  color: #808080;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 7.5px;
  bottom: 0;
  width: 2px;
  /* Adjust the width based on your image */
  background-image: url("../images/Home/Features/Verticalline.png");
  background-repeat: no-repeat;
}
.home-page .choose-kansensus-home-page .choose-kansensus-component {
  background-image: url("../images/Home/Choosesec/bg.png");
  background-position: right;
  background-repeat: no-repeat;
}
.home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content {
  width: 550px !important;
}
@media screen and (max-width: 991px) {
  .home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content {
    width: 100% !important;
    height: auto;
  }
}
.home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content h5 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */
  text-transform: capitalize;
  color: #ED1B23;
}
.home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-transform: capitalize;
  color: #323232;
}
.home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content h1 span {
  color: #ED1B23;
}
.home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content .para {
  width: 500px;
}
@media screen and (max-width: 991px) {
  .home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content .para {
    width: 100% !important;
    height: auto;
  }
}
.home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content .para p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */
  color: #808080;
}
@media screen and (max-width: 991px) {
  .home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-image {
    text-align: center;
  }
}
.home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-image img {
  width: 100%;
  height: auto;
}
.home-page .our-users-home-page .our-users-component {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1400px;
  /* Adjust based on your design */
  margin: 0 auto;
}
.home-page .our-users-home-page .our-users-component .content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-page .our-users-home-page .our-users-component .content h1 {
  width: 808px;
  height: 51px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 114.2%;
  text-align: center;
  color: #2a2222;
}
@media screen and (max-width: 750px) {
  .home-page .our-users-home-page .our-users-component .content h1 {
    width: 100%;
    height: auto;
  }
}
.home-page .our-users-home-page .our-users-component .content h3 {
  width: 649px;
  height: 42px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 134.2%;
  text-align: center;
  text-transform: capitalize;
  color: #484848;
}
@media screen and (max-width: 750px) {
  .home-page .our-users-home-page .our-users-component .content h3 {
    width: 100%;
    height: auto;
    height: auto;
  }
}
.home-page .our-users-home-page .our-users-component .button-btn {
  border: none !important;
  background: transparent !important;
  margin: 10px 20px;
}
.home-page .our-users-home-page .our-users-component .slider-container {
  width: 100%;
  margin: 0px;
  padding: 40px 0;
}
.home-page .our-users-home-page .our-users-component .slick-prev {
  border: none;
  background: transparent;
}
.home-page .our-users-home-page .our-users-component .slick-next {
  border: none;
  background: transparent;
}
.home-page .our-users-home-page .our-users-component .center .slick-slide {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.home-page .our-users-home-page .our-users-component .center .slick-center {
  opacity: 1;
  margin-top: 28px;
}
.home-page .our-users-home-page .our-users-component .slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.home-page .our-users-home-page .our-users-component .testimonial {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  max-width: 300px;
  margin: auto;
  height: 383.4px;
  background: #f5f2ed;
  border-radius: 10.8px;
}
.home-page .our-users-home-page .our-users-component .testimonial svg {
  width: 67.5px;
  height: 72px;
}
.home-page .our-users-home-page .our-users-component .testimonial .comment {
  width: 260.46px;
  height: 220.6px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 23.4px;
  line-height: 32px;
  letter-spacing: -0.63px;
  color: #413d45;
}
.home-page .our-users-home-page .our-users-component .testimonial .name {
  max-width: 260px;
  height: 17px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.126px;
  color: #67646a;
}
.home-page .our-users-home-page .our-users-component .testimonial .detail {
  max-width: 200px;
  height: 15px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.108px;
  color: #413d45;
}
.home-page .our-users-home-page .our-users-component .slick-prev:before,
.home-page .our-users-home-page .our-users-component .slick-next:before {
  color: #000;
}
.home-page .close-home-page .closer-component .content {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-page .close-home-page .closer-component .content h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-transform: capitalize;
  color: #323232;
}
.home-page .close-home-page .closer-component .content h3 {
  width: 817px;
  height: 56px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */
  text-align: center;
  color: #808080;
}
@media screen and (max-width: 800px) {
  .home-page .close-home-page .closer-component .content h3 {
    width: 100%;
    font-size: 16px;
    height: auto;
  }
}
.home-page .close-home-page .closer-component .add-banner {
  text-align: center;
}
.home-page .close-home-page .closer-component .add-banner img {
  width: 100%;
  height: auto;
}
.home-page .joinwaitlist-home-page .joinwaitlist-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 1279px;
  height: 302px;
  background-color: #ED1B23;
  border-radius: 30px;
  background-image: url("../images/Home/joincomponent/bg.png");
  /* Your background image */
  background-position: right center;
  /* Position the image to the right center */
  background-repeat: no-repeat;
  /* Ensure the image doesn't repeat */
  background-size: contain;
  /* Adjust the size of the image to fit the element */
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component {
    width: 100%;
    font-size: 44px;
    line-height: 54px;
  }
}
.home-page .joinwaitlist-home-page .joinwaitlist-component h1 {
  width: 689px;
  height: 85.16px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  text-transform: capitalize;
  color: #fafafa;
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component h1 {
    width: 100%;
    font-size: 44px;
    line-height: 54px;
  }
}
@media screen and (max-width: 400px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component h1 {
    width: 100%;
    height: auto;
    font-size: 24px;
    line-height: 34px;
  }
}
.home-page .joinwaitlist-home-page .joinwaitlist-component h5 {
  width: 574px;
  height: 34.28px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  text-transform: capitalize;
  color: #fafafa;
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component h5 {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component h5 {
    width: 100%;
    line-height: 54px;
  }
}
@media screen and (max-width: 400px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component h5 {
    margin-top: 10px !important;
    width: 100%;
    font-size: 14px;
    line-height: 34px;
  }
}
.home-page .joinwaitlist-home-page .joinwaitlist-component .email {
  width: 580px;
  height: 66.36px;
  border-radius: 50px;
  position: relative;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component .email {
    width: 450px;
  }
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component .email {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component .email {
    width: 300px;
  }
}
.home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper {
  position: relative;
  width: 100%;
}
.home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper .name {
  /* Rectangle 3 */
  box-sizing: border-box;
  width: 100%;
  height: 66.36px;
  background: white;
  border: none;
  font-family: "Lato";
  border-radius: 50px;
  color: black;
  font-size: 14px;
  font-weight: 600;
}
.home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper button {
  width: 174px;
  height: 49px;
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper button {
    width: 100px;
  }
}
.home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper .email-button {
  gap: 10px;
  position: absolute;
  right: 12px;
  top: 32px;
  transform: translateY(-50%);
  background: #ED1B23;
  border-radius: 50px;
  border: none;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper .email-button {
    font-size: 13px;
  }
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper .email-button {
    display: none;
  }
}
.home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper .email-button:hover {
  background-color: #ED1B23;
  /* Darker shade for hover effect */
}
.home-page .joinwaitlist-home-page .joinwaitlist-component .email-button-mobile {
  margin-top: 10px;
  gap: 10px;
  transform: translateY(-50%);
  background: #ED1B23;
  border-radius: 50px;
  border: none;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.page-fotter {
  max-width: 1402px !important;
  height: 383px;
  /* background: #000000; */
  /* background: #111322; */
  border-radius: 52px 52px 0px 0px;
  padding: 80px 70px;
}

.page-fotter img{
  width: 200px;
}

@media screen and (max-width: 750px) {
  .page-fotter {
    height: 883px;
  }
}

@media screen and (max-width: 1250px) {
  .page-fotter .horizontol-line {
    width: 1100px;
  }
}
@media screen and (max-width: 1170px) {
  .page-fotter .horizontol-line {
    width: 1030px;
  }
}
@media screen and (max-width: 1100px) {
  .page-fotter .horizontol-line {
    width: 950px;
  }
}
@media screen and (max-width: 820px) {
  .page-fotter .horizontol-line {
    display: none;
  }
}
.page-fotter h1 {
  margin-bottom: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 42px;
  /* identical to box height, or 191% */
  text-transform: capitalize;
  /* color: #ffffff; */
}
.page-fotter ul li {
  margin-bottom: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  /* identical to box height, or 186% */
  text-transform: capitalize;
  /* color: #ffffff; */
}
.page-fotter .brand-col .brand-name img {
  margin-right: 10px;
  width: 60px;
}
.page-fotter .brand-col .brand-name p {
  font-family: "Bahnschrift";
  font-style: normal;
  font-weight: 400;
  font-size: 38.6202px;
  line-height: 46px;
  letter-spacing: -0.01em;
  /* color: #ffffff; */
}
.page-fotter .brand-col .intro {
  width: 221px;
  height: 54px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */
  text-transform: capitalize;
  /* color: #ffffff; */
}
@media screen and (max-width: 750px) {
  .page-fotter .contact-us {
    text-align: center;
    margin-top: 20px;
  }
}
.page-fotter .contact-us .contact-us-content svg {
  margin-right: 10px;
}
.page-fotter .contact-us .contact-us-content span {
  margin-bottom: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  /* identical to box height, or 186% */
  text-transform: capitalize;
  /* color: #ffffff; */
}
.page-fotter .copy-right {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  text-transform: capitalize;
  /* color: #ffffff; */
  margin-left: 39px;
}
@media screen and (max-width: 820px) {
  .page-fotter .copy-right {
    display: none !important;
  }
}
.page-fotter .social-media {
  margin-right: 59px;
}
@media screen and (max-width: 820px) {
  .page-fotter .social-media {
    display: none !important;
  }
}
.page-fotter .social-media svg {
  margin-left: 10px;
  cursor: pointer;
}
.page-fotter .tablet-social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.page-fotter .tablet-social .copy-right-tablet {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  text-transform: capitalize;
  /* color: #ffffff; */
  margin-left: 39px;
}
.page-fotter .tablet-social .social-media-tablet {
  margin-right: 59px;
  margin-left: 20%;
  margin-top: 20px;
}
.page-fotter .tablet-social .social-media-tablet svg {
  margin-left: 10px;
  cursor: pointer;
}
@media screen and (max-width: 750px) {
  .page-fotter .links {
    text-align: center;
    margin-top: 20px;
  }
}

.top-nav-bar {
  /* background-color: #111322; */
  /* background-color: #ED1B23; */
  padding: 30px 0px;
}

.top-nav-bar img {
  width: 60px;
}

@media screen and (max-width: 370px) {
  .top-nav-bar .navbar-brand {
    width: 50%;
  }
}
.top-nav-bar .nav-link {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 100%;
  color: #000;
}
.top-nav-bar a {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 100%;
  color: #000;
}
.top-nav-bar .btn-contact-us {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 148px;
  height: 44px;
  margin-top: 12px;
}
.top-nav-bar .navbar-expand .navbar-nav {
  margin: 20px 22px;
}
.top-nav-bar .navbar .nav-link.active {
  border-bottom: 2px solid #ED1B23;
  /* Example bottom border style */
  font-family: "Gilroy";
  padding-bottom: 10px;
  /* Adjust spacing as needed */
  color: #ED1B23;
  /* Example text color for active link */
}

.top-nav-bar .navbar .active {
  border-bottom: 2px solid #ED1B23;
  font-family: "Gilroy";
  padding-bottom: 10px;
  color: #ED1B23;
}

.terms-and-condition h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-transform: capitalize;
  color: #323232;
}
.terms-and-condition h2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-transform: capitalize;
  color: #867cf2 !important;
}
.terms-and-condition p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 29px;
  /* or 172% */
  text-transform: capitalize;
  color: #413d45;
}/*# sourceMappingURL=style.css.map */

.contact-us label{
  /* font-size: 10px; */
  font-weight: bold;
}

.contact-us input {
  font-size: 14px;
  background: white;
}

.contact-us textarea {
  font-size: 14px;
}

.contact-us button {
  /* font-size: 14px; */
  background: #ED1B23;
  color: white;
  border-radius: 26px;
}

.contact-us button:hover {
  background: #ED1B23;
  color: white;
}