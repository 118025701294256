.top-nav-bar {
  background-color: $top-nav-color;
  padding: 30px 0px;
  .navbar-brand {
    @media screen and (max-width: 370px) {
      width: 50%;
    }
  }
  .nav-link {
    font-family: "Lato";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 100%;
    // display: flex;
    // align-items: center;
    color: #ffffff;
  }

  .btn-contact-us {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 148px;
    height: 44px;
    margin-top: 12px;
    // padding: 19px 47px;
    // width: 190px;
    // height: 56px;
    // background: #005B41;
    // border-radius: 15px;
    // border: #005B41;
  }

  .navbar-expand .navbar-nav {
    margin: 20px 22px;
  }

  .navbar {
    .nav-link.active {
      border-bottom: 2px solid #fff;
      /* Example bottom border style */
      padding-bottom: 10px;
      /* Adjust spacing as needed */
      color: #fff;
      /* Example text color for active link */
    }
  }
}
